<template>
  <super-admin-page-card
    pageName="User Edits - BDI"
    iconClass="fa fa-pencil-square-o fa-lg"
  >
    <div>
      <v-wait for="fetchingUserEdits">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>

        <div class="row">
          <div class="col-sm-3 offset-sm-9 mb-3">
            <b-input-group>
              <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <b-form-input
                type="text"
                v-model="filter"
                placeholder="Search"
                ref="search"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>

        <b-table
          :items="userEdits"
          :fields="fields"
          :show-empty="true"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          @filtered="onFiltered"
          empty-text="No records found."
          responsive="md"
          striped
          hover
        >
          <template v-slot:cell(edited_by)="data">
            <div>{{ data.item.first_name }} ({{ data.item.last_name }})</div>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              size="sm"
              variant="primary"
              @click="showBDIVerificationModal(data.item)"
              >Verify</b-button
            >
          </template>
        </b-table>

        <b-row>
          <b-col md="6">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
            >
            </b-pagination>
          </b-col>
        </b-row>

        <b-modal
          id="bdiVerificationModal"
          size="xl"
          @shown="prepareForBDIVerification"
          hide-footer
        >
          <template #modal-title>
            {{ verificationModalTitle() }}
          </template>

          <v-wait for="fetchingUserEditChanges">
            <template slot="waiting">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>

            <div v-if="newVendorAddition">
              <div class="row mb-2">
                <div class="col-sm-3"><h6>Financial Institution:</h6></div>
                <div class="col-sm-4">
                  {{ userEditChange.bank_name }},
                  {{ userEditChange.bank_city }},
                  {{ userEditChange.bank_state }}({{ userEditChange.bank_id }})
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-3"><h6>Bank Service:</h6></div>
                <div class="col-sm-4">
                  <router-link
                    tag="a"
                    :to="{
                      path: `/technographics/service_report/${userEditChange.bank_service_id}`
                    }"
                    >{{ userEditChange.bank_service }}</router-link
                  >
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-3"><h6>New Vendor:</h6></div>
                <div class="col-sm-4">
                  {{ userEditChange.new_vendor }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-3"><h6>New Product:</h6></div>
                <div class="col-sm-4">
                  {{ userEditChange.new_product }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-3"><h6>Vendor Website:</h6></div>
                <div class="col-sm-4">
                  <a
                    target="_blank"
                    v-if="userEditChange.vendor_url"
                    :href="formatLink(userEditChange.vendor_url)"
                    >{{ userEditChange.vendor_url }}</a
                  >
                </div>
              </div>
              <div class="row my-3 text-center">
                <div class="col-sm-12">
                  <b-button
                    size=""
                    variant="secondary"
                    @click="updateStatus(userEditChange, 'REVERTED')"
                    class="fw-btn mt-3 mx-3 px-2"
                    >Reject</b-button
                  >
                  <b-button
                    size=""
                    variant="primary"
                    @click="updateStatus(userEditChange, 'APPROVED')"
                    class="fw-btn mt-3 mx-3 px-2"
                    >Approve</b-button
                  >
                </div>
              </div>
            </div>
            <div v-else-if="newProductAddition">
              <div class="row mb-2">
                <div class="col-sm-3"><h6>Financial Institution:</h6></div>
                <div class="col-sm-4">
                  {{ userEditChange.bank_name }},
                  {{ userEditChange.bank_city }},
                  {{ userEditChange.bank_state }}({{ userEditChange.bank_id }})
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-3"><h6>Bank Service:</h6></div>
                <div class="col-sm-4">
                  <router-link
                    tag="a"
                    :to="{
                      path: `/technographics/service_report/${userEditChange.bank_service_id}`
                    }"
                    >{{ userEditChange.bank_service }}</router-link
                  >
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-3"><h6>Vendor:</h6></div>
                <div class="col-sm-4">
                  {{ userEditChange.new_vendor }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-3"><h6>New Product:</h6></div>
                <div class="col-sm-4">
                  {{ userEditChange.new_product }}
                </div>
              </div>
              <div class="row my-3 text-center">
                <div class="col-sm-12">
                  <b-button
                    size=""
                    variant="secondary"
                    @click="updateStatus(userEditChange, 'REVERTED')"
                    class="fw-btn mt-3 mx-3 px-2"
                    >Reject</b-button
                  >
                  <b-button
                    size=""
                    variant="primary"
                    @click="updateStatus(userEditChange, 'APPROVED')"
                    class="fw-btn mt-3 mx-3 px-2"
                    >Approve</b-button
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <b-table
                :items="pendingUserEditChanges"
                :fields="bdiEditFields"
                :show-empty="true"
                empty-text="No records found."
                responsive="md"
                striped
                hover
              >
                <template v-slot:cell(bank_name)="data">
                  <div>
                    {{ data.item.bank_name }}, {{ data.item.bank_city }},
                    {{ data.item.bank_state }}({{ data.item.bank_id }})
                  </div>
                </template>
                <template v-slot:cell(bank_service)="data">
                  <div>
                    <router-link
                      tag="a"
                      :to="{
                        path: `/technographics/service_report/${data.item.bank_service_id}`
                      }"
                      >{{ data.item.bank_service }}</router-link
                    >
                  </div>
                </template>
                <template v-slot:cell(service_change)="data">
                  <div
                    v-if="
                      data.item.new_has_service === data.item.old_has_service
                    "
                  >
                    {{ data.item.old_has_service | titleCase }}

                    <b-badge pill variant="secondary" class="px-1"
                      >No Change</b-badge
                    >
                  </div>
                  <div v-else>
                    {{
                      [true, false].includes(data.item.old_has_service)
                        ? hasServiceTitleCase(data.item.old_has_service)
                        : 'NA'
                    }}
                    <i class="fa fa-arrow-right px-1" aria-hidden="true"></i>
                    {{
                      [true, false].includes(data.item.new_has_service)
                        ? hasServiceTitleCase(data.item.new_has_service)
                        : 'NA'
                    }}
                  </div>
                </template>
                <template v-slot:cell(vendor_change)="data">
                  <div
                    v-if="data.item.new_vendor_id === data.item.old_vendor_id"
                  >
                    {{ data.item.old_vendor || 'NA' }}
                    <b-badge pill variant="secondary" class="px-1"
                      >No Change</b-badge
                    >
                  </div>
                  <div v-else>
                    {{ data.item.old_vendor || 'NA' }}
                    <i class="fa fa-arrow-right px-1" aria-hidden="true"></i>
                    {{ data.item.new_vendor || 'NA' }}
                  </div>
                </template>
                <template v-slot:cell(product_change)="data">
                  <div
                    v-if="data.item.new_product_id === data.item.old_product_id"
                  >
                    {{ data.item.old_product || 'NA' }}
                    <b-badge pill variant="secondary" class="px-1"
                      >No Change</b-badge
                    >
                  </div>
                  <div v-else>
                    {{ data.item.old_product || 'NA' }}
                    <i class="fa fa-arrow-right px-1" aria-hidden="true"></i>
                    {{ data.item.new_product || 'NA' }}
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <b-button
                    size="sm"
                    class="py-0 mx-1"
                    variant="primary"
                    @click="updateStatus(data.item, 'APPROVED')"
                    >Approve</b-button
                  >
                  <b-button
                    size="sm"
                    class="py-0"
                    variant="secondary"
                    @click="updateStatus(data.item, 'REVERTED')"
                    >Reject</b-button
                  >
                </template>
              </b-table>
            </div>
          </v-wait>
        </b-modal>
      </v-wait>
    </div>
  </super-admin-page-card>
</template>

<script>
// api
import userEditsAPI from '@/api/finapps/super_admin/user_edits'
// utilities
import { formatUrl } from '@/utilities'

export default {
  name: 'UserEdits',
  components: {},
  data() {
    return {
      perPage: 20,
      totalRows: 1,
      filter: null,
      currentPage: 1,
      userEdits: [],
      userEditChanges: [],
      selectedUserEdit: null,
      fields: [
        { key: 'editable_type', label: 'Type', sortable: true },
        { key: 'edited_by', label: 'Edited By', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'company_type', label: 'Company Type', sortable: true },
        { key: 'updated_at', label: 'Last Updated On', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      bdiEditFields: [
        { key: 'bank_name', label: 'Financial Institution' },
        { key: 'bank_service', label: 'Bank Service', sortable: true },
        { key: 'service_change', label: 'Has Service' },
        { key: 'vendor_change', label: 'Vendor Change' },
        { key: 'product_change', label: 'Product Change' },
        { key: 'actions', label: 'Actions', tdClass: 'action-column' }
      ]
    }
  },
  created() {
    this.loadInitData()
  },
  computed: {
    userEditType() {
      if (!this.selectedUserEdit) {
        return null
      }

      return this.selectedUserEdit.editable_type === 'Add New Vendor'
        ? 'newVendor'
        : this.selectedUserEdit.editable_type === 'Add New Product'
        ? 'newProduct'
        : 'BDI Bulk Update'
    },
    newVendorAddition() {
      return this.userEditType === 'newVendor'
    },
    newProductAddition() {
      return this.userEditType === 'newProduct'
    },
    pendingUserEditChanges() {
      return this.userEditChanges.filter(ue =>
        ['pending', 'pending_for_update'].includes(
          ue.status.toLocaleLowerCase()
        )
      )
    },
    userEditChange() {
      return this.userEditChanges[0] || {}
    }
  },
  methods: {
    loadInitData() {
      this.$wait.start('fetchingUserEdits')

      userEditsAPI.allEdits().then(res => {
        this.userEdits = res.user_edits
        this.totalRows = this.userEdits.length

        this.$wait.end('fetchingUserEdits')
      })
    },
    showBDIVerificationModal(userEdit) {
      this.selectedUserEdit = userEdit
      this.$bvModal.show('bdiVerificationModal')
    },
    prepareForBDIVerification() {
      this.$wait.start('fetchingUserEditChanges')

      userEditsAPI.showEdit(this.selectedUserEdit.id).then(res => {
        this.userEditChanges = res.edited_bdis
        this.$wait.end('fetchingUserEditChanges')
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    verificationModalTitle() {
      let title

      if (this.newVendorAddition) {
        title = 'New Vendor/Product'
      } else if (this.newProductAddition) {
        title = 'New Product'
      } else {
        title = 'BDI Bulk Update'
      }

      return title
    },
    updateStatus(editedBDI, status) {
      userEditsAPI.updateEdit(editedBDI, status).then(() => {
        let matchingUserEditBDI = this.userEditChanges.find(
          ue => ue.id === editedBDI.id
        )
        matchingUserEditBDI.status = status

        if (!this.pendingUserEditChanges.length) {
          this.$bvModal.hide('bdiVerificationModal')
          this.loadInitData()
        }

        let message =
          status === 'APPROVED'
            ? 'Changes approved successfully.'
            : 'Changes rejected.'

        this.$toasted.global.action_success(message)
      })
    },
    hasServiceTitleCase(hasService) {
      return hasService
        .toString()
        .toLowerCase()
        .split(/\s|_/)
        .map(function(word) {
          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(' ')
    },
    formatLink(url) {
      return formatUrl(url)
    }
  }
}
</script>
<style lang="scss">
.action-column {
  min-width: 175px;
}
</style>
