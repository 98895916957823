import Vue from 'vue'
import axios from 'axios'

export default {
  allEdits() {
    return axios
      .get('/api/super_admin/user_edits', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  showEdit(userEditID) {
    return axios
      .get(`/api/super_admin/user_edits/${userEditID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  updateEdit(editedBDI, status) {
    return axios
      .put(
        `/api/super_admin/user_edits/${editedBDI.id}`,
        {
          edited_bank_detail_inferences: {
            status: status
          }
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        () => {
          Vue.toasted.show(
            'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
            {
              icon: 'chain-broken',
              type: 'error'
            }
          )

          return Promise.reject('validation failed')
        }
      )
  }
}
